import MDEditor, { commands } from '@uiw/react-md-editor';
import { Button, Icon } from 'antd';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './Markdown.css';

export const textSuperscript = {
  name: 'Superscript',
  keyCommand: 'superscript',
  buttonProps: {
    'aria-label': 'Add a superscript',
    title: 'Add a superscript',
  },
  icon: (
    // <!-- Generated by IcoMoon.io -->
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 2 24 24">
      <path
        fill="currentColor"
        d="M14.016 21.391v2.609h-3.875l-2.484-3.938-0.375-0.656c-0.094-0.109-0.141-0.219-0.172-0.328h-0.047c-0.031 0.109-0.094 0.219-0.141 0.328-0.094 0.187-0.234 0.438-0.391 0.688l-2.422 3.906h-4.031v-2.609h2l3.078-4.547-2.891-4.25h-2.141v-2.625h4.312l2.172 3.563c0.141 0.219 0.25 0.453 0.359 0.656 0.094 0.109 0.141 0.219 0.172 0.328h0.047c0.031-0.109 0.094-0.219 0.172-0.328l0.391-0.656 2.188-3.563h4.016v2.625h-1.953l-2.875 4.172 3.187 4.625h1.703zM23.969 10.781v3.219h-8.031l-0.047-0.422c-0.031-0.219-0.063-0.516-0.063-0.719 0-4.266 5.469-4.625 5.469-6.891 0-0.812-0.734-1.359-1.563-1.359-0.594 0-1.125 0.281-1.516 0.609-0.203 0.172-0.391 0.391-0.562 0.594l-1.641-1.437c0.281-0.391 0.594-0.719 0.984-1.031 0.656-0.516 1.609-1.016 2.938-1.016 2.266 0 3.844 1.328 3.844 3.406 0 3.75-5.187 4.062-5.406 6.297h3.625v-1.25h1.969z"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    let modifyText = `<sup>${state.selectedText}</sup>`;
    if (!state.selectedText) {
      modifyText = '<sup></sup>';
    }
    const textState = api.replaceSelection(modifyText);
    api.setSelectionRange({
      start: textState.selection.end - 6,
      end: textState.selection.end - 6,
    });
  },
};

interface MarkdownInputProps {
  value: any;
  error?: boolean;
  disabled?: boolean;
  onChange: (value?: string) => void;
}
const MarkdownInput: FC<MarkdownInputProps> = ({
  value,
  error,
  disabled,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      {editMode && !disabled ? (
        <div>
          <div className="md-text-toolbar-helpers">
            <Button size="small" onClick={() => setEditMode(false)}>
              <Icon type="eye" />
              {formatMessage({ id: 'resourceTypeDetail.text.preview' })}
            </Button>
            <span>
              <FormattedMessage
                id="markdown-info-message"
                values={{
                  link: 'here',
                  a: (chunks: string) => (
                    <a
                      href="https://commonmark.org/help/"
                      rel="noopener noreferrer"
                      target="_blank">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </span>
          </div>
          <MDEditor
            className={`md-text-editor ${error ? 'md-editor--error' : ''}`}
            value={value}
            onChange={onChange}
            textareaProps={{ autoFocus: true }}
            commands={[
              // Custom Toolbars
              commands.bold,
              commands.divider,
              commands.italic,
              commands.divider,
              commands.unorderedListCommand,
              commands.divider,
              commands.orderedListCommand,
              commands.divider,
              commands.link,
              commands.divider,
              textSuperscript,
            ]}
            preview="edit"
            visiableDragbar={true}
          />
        </div>
      ) : (
        <div
          className={`md-text-preview ${
            disabled ? 'md-preview--disabled' : error ? 'md-preview--error' : ''
          }`}
          onClick={() => setEditMode(true)}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{value}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default MarkdownInput;
